import React, { useRef, useEffect } from 'react'
import styled from '@emotion/styled'

const StyledWrapper = styled.div`
  width: 100%;

  &:focus {
    outline-offset: 2px;
    outline-color: black;
  }
`

type WidgetTypes = {
  mini: string
  horizontal: string
  microCombo: string
}
const widgetTypes: WidgetTypes = {
  mini: '53aa8807dec7e10d38f59f32',
  horizontal: '5406e65db0d04a09e042d5fc',
  microCombo: '5419b6ffb0d04a076446a9af'
}

type TrustPilotBoxProps = {
  widget?: keyof WidgetTypes
}

const TrustPilotBox = (p: TrustPilotBoxProps) => {
  const trustBoxRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    /* eslint-disable */
    // @ts-ignore
    if (window.Trustpilot != null) window.Trustpilot.loadFromElement(trustBoxRef.current, true)
    /* eslint-enable */
  }, [])

  return (
    <StyledWrapper onClick={() => (window.location.href = 'https://uk.trustpilot.com/review/kriya.co')}>
      <div
        ref={trustBoxRef}
        className="trustpilot-widget"
        data-locale="en-GB"
        data-template-id={p.widget === undefined ? widgetTypes.microCombo : widgetTypes[p.widget]}
        data-businessunit-id="63a219862c4ca815c8995bbd"
        data-style-width="100%"
        data-theme="light"
        style={{ paddingLeft: '8px' }}
      >
        <a href="https://uk.trustpilot.com/review/kriya.co" target="_blank" rel="noopener noreferrer">
          Trustpilot
        </a>
      </div>
    </StyledWrapper>
  )
}

export default TrustPilotBox
