import { useCookies } from 'react-cookie'
import { v4 as uuidv4 } from 'uuid'
import { useEffect } from 'react'
import { cookieNames, getCookieDomain } from '../utils/cookieUtils'

const useTrackingCookie = (): string => {
  const [cookies, setCookie] = useCookies([cookieNames.analyticsUid])

  useEffect(() => {
    if (!cookies[cookieNames.analyticsUid]) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
      const newUserId: string = uuidv4()

      setCookie(cookieNames.analyticsUid, newUserId, {
        path: '/',
        expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
        secure: true,
        sameSite: 'lax',
        domain: getCookieDomain()
      })
    }
  }, [cookies, setCookie])

  const userId: string | null = (cookies[cookieNames.analyticsUid] as string | undefined) || null

  return userId || ''
}

export default useTrackingCookie
