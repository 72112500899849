import React from 'react'
import { OnboardingJourney } from '../../types/OnboardingJourney'
import LimitsBox from '../../components/LimitsBox'
import Logo from '../../components/Logo'
import { Content } from '../../assets/styles/ContentStyles'
import Box from '@mui/material/Box'
import theme from '../../assets/theme'
import Typography from '@mui/material/Typography'
import { OnboardingJourneySource } from '../../types/enums/OnboardingJourneySource'
import ActionButton from '../../components/ActionButton'
import Link from '@mui/material/Link'
import Tooltip from '@mui/material/Tooltip'
import useVirtualPageView from '../../custom-hooks/useVirtualPageView'
import { VirtualPage } from '../../types/enums/VirtualPage'
import useTrackEvent from '../../custom-hooks/useTrackEvent'
import { EventTypes } from '../../types/enums/TrackEventType'

const ChecksCompletionPage = (data: OnboardingJourney) => {
  useVirtualPageView(VirtualPage.CHECKS_PASSED)
  const trackEvent = useTrackEvent()

  const returnToMerchantUrl =
    data.source === OnboardingJourneySource.PaymentJourney ? data.abandonedUrl : data.completedUrl

  const handleDirectDebitClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()

    trackEvent(EventTypes.OnboardingOutcomes.Passed.CLICK_DIRECT_DEBIT_LINK)

    window.open('https://pay.gocardless.com/BRT0002NN3HDCWT', '_blank', 'noopener,noreferrer')
  }

  return (
    <>
      {data.merchant.logoUri ? <Logo logoUri={data.merchant.logoUri}></Logo> : <br />}
      <Box
        sx={{
          marginTop: { xs: theme.spacing(24), sm: theme.spacing(32) }
        }}
      >
        <Content xsGapSize={theme.spacing(24)} smGapSize={theme.spacing(20)}>
          <Typography variant="h4" data-cy="message">
            Onboarding completed successfully. Your spending limit is now activated
          </Typography>
          <LimitsBox
            companyName={data.company.name}
            totalSpendingLimit={data.riskDecision.totalSpendingLimit}
            availableSpendingLimit={data.riskDecision.availableSpendingLimit}
            currency={data.riskDecision.currency}
            eligibilitySubject={'to use Kriya PayLater'}
          />
          <Content xsGapSize={theme.spacing(12)} smGapSize={theme.spacing(12)}>
            <Typography variant="h4" data-cy="sub-title-further-actions">
              What's next
            </Typography>
            <Typography variant="body2" data-cy="next-actions">
              {`You can now close this window and start shopping at ${data.merchant.name}.`}
            </Typography>
            {returnToMerchantUrl !== null && data.source !== OnboardingJourneySource.MarketplacePortal && (
              <ActionButton
                onClick={() => {
                  window.location.replace(returnToMerchantUrl)
                }}
                id="return-to-merchant-btn"
              >
                Go to {data.merchant.name}
              </ActionButton>
            )}
          </Content>
          <Tooltip
            id="direct-debit-tooltip"
            title="Direct Debit is offered by our partner, GoCardless. If choosing to pay with Direct Debit, Kriya will take payments for all outstanding invoices through this payment option."
            placement="top-start"
            style={{ outline: 'none' }}
            tabIndex={0}
            role="tooltip"
            data-cy="direct-debit-tooltip"
          >
            <Link
              variant="body2"
              href="#"
              onClick={handleDirectDebitClick}
              data-cy="direct-debit-link"
              sx={{
                color: theme.palette.common.linkHover
              }}
            >
              Interested in paying with Direct Debit? Sign up here.
            </Link>
          </Tooltip>
        </Content>
      </Box>
    </>
  )
}

export default ChecksCompletionPage
