import { useMutation, useQueryClient } from 'react-query'

import { UpdateOnboardingJourneyResponse } from '../types/UpdateOnboardingJourneyResponse'
import { apiClient } from '../api/apiClient'
import { fetchOnboardingJourneyKey } from './useOnboardingJourneyQuery'
import routes from '../routes'
import { useNavigate } from 'react-router-dom'
import { OnboardSoleTraderRequest } from '../types/OnboardSoleTraderRequest'

export const onboardSoleTrader = async (
  onboardingJourneyInfo: OnboardSoleTraderRequest
): Promise<UpdateOnboardingJourneyResponse> => {
  const response = await apiClient.post<UpdateOnboardingJourneyResponse>('onboardSoleTrader', onboardingJourneyInfo)
  return response.data
}

export const useOnboardSoleTrader = ({
  onSuccess,
  onError
}: {
  onSuccess?: (data: UpdateOnboardingJourneyResponse) => void
  onError?: (error: { status: number }) => void
}) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation(
    async (onboardSoleTraderProperties: OnboardSoleTraderRequest): Promise<UpdateOnboardingJourneyResponse> => {
      const data = await onboardSoleTrader(onboardSoleTraderProperties)
      return data
    },
    {
      onSuccess: async (data) => {
        await queryClient.invalidateQueries(fetchOnboardingJourneyKey)
        if (onSuccess) {
          onSuccess(data)
        }
      },
      onError: (error: { response: { status: number } }) => {
        if (onError) {
          onError({
            status: error.response.status
          })
        } else {
          navigate(routes.error)
        }
      }
    }
  )

  return { mutate, isLoading }
}
