import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import ErrorPageContainer, { StyledErrorPageButtonContainer } from './ErrorPageContainer'
import PageLoading from '../../components/loading/PageLoading'
import { useOnboardingJourneyInfo } from '../../custom-hooks/useOnboardingJourneyQuery'
import { useParams } from 'react-router-dom'
import { OnboardingJourneySource } from '../../types/enums/OnboardingJourneySource'
import { VirtualPage } from '../../types/enums/VirtualPage'
import useVirtualPageView from '../../custom-hooks/useVirtualPageView'

interface InReviewProps {
  source: OnboardingJourneySource
}

const InReview = (props: InReviewProps) => {
  const virtualPageType =
    props.source === OnboardingJourneySource.PaymentJourney ? VirtualPage.RISK_REVIEW : VirtualPage.CHECKS_REVIEW
  useVirtualPageView(virtualPageType)

  const { merchantId, merchantCompanyIdentifier } = useParams()
  const { data, dataLoading } = useOnboardingJourneyInfo(merchantId, merchantCompanyIdentifier)

  if (dataLoading) return <PageLoading title="Loading..." />

  const handleReturnClick = () => {
    window.location.replace(data?.inReviewUrl as string)
  }

  return (
    <ErrorPageContainer title="Review in progress">
      <Box data-cy="message">
        Our team is conducting a manual review of your data. Once the evaluation is complete, we'll notify you via
        email. For any assistance in the meantime, reach out to us at{' '}
        <Link href="mailto:customersupport@kriya.co">customersupport@kriya.co</Link>.
      </Box>

      {data?.inReviewUrl && (
        <StyledErrorPageButtonContainer>
          <Button variant="contained" onClick={handleReturnClick} id="return-to-merchant-btn">
            Return to {data?.merchant.name}
          </Button>
        </StyledErrorPageButtonContainer>
      )}
    </ErrorPageContainer>
  )
}

export default InReview
