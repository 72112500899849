import { useMutation, useQueryClient } from 'react-query'

import { apiClient } from '../api/apiClient'
import { fetchOnboardingJourneyKey } from './useOnboardingJourneyQuery'
import routes from '../routes'
import { useNavigate, useParams } from 'react-router-dom'
import { InitiateSoleTraderOnboardingJourneyRequest } from '../types/InitiateSoleTraderOnboardingJourneyRequest'

export const initiateSoleTraderOnboardingJourneyDetails = async (
  merchantId: string,
  onboardingJourneyIdentifier: string,
  directorEmail?: string,
  directorPhoneNumber?: string
): Promise<number> => {
  const response = await apiClient.post<number>('initiateSoleTraderFlow', {
    merchantId,
    onboardingJourneyIdentifier,
    directorEmail,
    directorPhoneNumber
  })
  return response.status
}

export const useInitiateSoleTraderOnboardingJourney = ({
  onSuccess,
  onError
}: {
  onSuccess?: () => void
  onError?: () => void
}) => {
  const { merchantId, merchantCompanyIdentifier } = useParams()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation(
    async (data: InitiateSoleTraderOnboardingJourneyRequest): Promise<number> => {
      return await initiateSoleTraderOnboardingJourneyDetails(
        merchantId!,
        merchantCompanyIdentifier!,
        data.directorEmail,
        data.directorPhoneNumber
      )
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(fetchOnboardingJourneyKey)
        if (onSuccess) {
          onSuccess()
        }
      },
      onError: () => {
        if (onError) {
          onError()
        } else {
          navigate(routes.error)
        }
      }
    }
  )

  return { mutate, isLoading }
}
