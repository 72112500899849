import Link from '@mui/material/Link'
import useTrackEvent from '../custom-hooks/useTrackEvent'
import { EventTypes } from '../types/enums/TrackEventType'

type EligibilityRetryProps = {
  handleClick: () => void
}

const EligibilityRetry = ({ handleClick }: EligibilityRetryProps) => {
  useTrackEvent(EventTypes.Eligibility.RISK_DECISION_RETRY)
  return (
    <div style={{ maxWidth: '600px', flexGrow: 1 }}>
      Oops... It's taking longer than expected. Please give it another try by{' '}
      <Link component="button" variant="h1" onClick={handleClick}>
        clicking here
      </Link>
    </div>
  )
}

export default EligibilityRetry
