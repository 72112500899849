import { MESSAGES, createVeriffFrame } from '@veriff/incontext-sdk'
import React, { useEffect, useRef } from 'react'
import { UseMutateFunction } from 'react-query'
import useVirtualPageView from '../../../../custom-hooks/useVirtualPageView'
import { VirtualPage } from '../../../../types/enums/VirtualPage'

export type SelfieCheckProps = {
  abandonedUrl?: string
  kycSessionUrl?: string
  onSubmit: UseMutateFunction<void, unknown, {}, unknown>
}
export const veriffFrameId = 'load-veriff-iframe'

const SelfieCheck = ({ kycSessionUrl, abandonedUrl, onSubmit }: SelfieCheckProps) => {
  useVirtualPageView(VirtualPage.SELFIE_CHECK)
  const myElementRef = useRef<any>(null)

  useEffect(() => {
    let shouldCleanup = false

    if (myElementRef.current?.id !== veriffFrameId) {
      myElementRef.current = createVeriffFrame({
        url: kycSessionUrl!,
        onEvent: function (msg) {
          switch (msg) {
            case MESSAGES.CANCELED:
              window.location.replace(abandonedUrl!)
              shouldCleanup = true
              break
            case MESSAGES.FINISHED:
              onSubmit({})
              shouldCleanup = true
              break
          }
        }
      })
      myElementRef.current.id = veriffFrameId
    }

    return () => {
      if (myElementRef.current?.id === veriffFrameId && shouldCleanup) {
        myElementRef.current.close()
      }
    }
  }, [kycSessionUrl, abandonedUrl, onSubmit])

  return <div ref={myElementRef}></div>
}

export default SelfieCheck
