import { OnboardingJourney } from '../../../types/OnboardingJourney'
import { FlowType } from '../../../types/enums/FlowType'
import DirectorLimitedCompanyStartPage from './limitedCompany/DirectorLimitedCompanyStartPage'
import DirectorSoleTraderStartPage from './soleTrader/DirectorSoleTraderStartPage'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const DirectorChecksStartPage = (data: OnboardingJourney) => {
  const navigate = useNavigate()

  if (!data.flowType) {
    navigate('/error')
  }

  const flowTypeComponentMap: Record<FlowType, React.ComponentType<OnboardingJourney>> = {
    [FlowType.LimitedCompany]: DirectorLimitedCompanyStartPage,
    [FlowType.SoleTrader]: DirectorSoleTraderStartPage
  }

  const StartPageComponent = flowTypeComponentMap[data.flowType!]

  return <StartPageComponent {...data} />
}

export default DirectorChecksStartPage
