import Link from '@mui/material/Link'
import ErrorPageContainer from './ErrorPageContainer'
import Box from '@mui/material/Box'
import useVirtualPageView from '../../custom-hooks/useVirtualPageView'
import { VirtualPage } from '../../types/enums/VirtualPage'

const NotFound = () => {
  useVirtualPageView(VirtualPage.INVALID_LINK)

  return (
    <ErrorPageContainer title="Link not found">
      <Box data-cy="message">Hmm… You’ve entered an invalid link. Please try again.</Box>
      <br />
      <Box data-cy="contact-details">
        If you need any assistance, please contact{' '}
        <Link href="mailto:customersupport@kriya.co">customersupport@kriya.co</Link>.
      </Box>
    </ErrorPageContainer>
  )
}

export default NotFound
