export const formatCurrency = (amount: number | null, currencyCode: string | null): string | undefined => {
  if (amount != null && currencyCode) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(amount)
  }
  return undefined
}
