import React, { useEffect, useMemo } from 'react'
import Typography from '@mui/material/Typography'
import theme from '../../assets/theme'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import TrustPilotBox from '../../components/TrustPilot'
import { useCheckOnboardingStatus } from '../../custom-hooks/useCheckOnboardingStatus'
import { OnboardingJourney } from '../../types/OnboardingJourney'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { OnboardingJourneyStatus } from '../../types/enums/OnboardingJourneyStatus'
import routes from '../../routes'
import Backdrop from '@mui/material/Backdrop'

const NonDirectorPollingPageContent = ({ data }: { data: OnboardingJourney }) => {
  const { merchantId, merchantCompanyIdentifier } = useParams()
  const navigate = useNavigate()
  const { startPolling, isPolling } = useCheckOnboardingStatus(data.id)

  const directorName = useMemo(() => data.director.fullName, [data])
  const returnUrl = useMemo(() => data.abandonedUrl, [data])

  useEffect(() => {
    if (data.status == OnboardingJourneyStatus.OnboardingJourneyIsSuccessfullyCompleted) {
      navigate(
        generatePath(routes.home, {
          merchantId,
          merchantCompanyIdentifier
        })
      )
    }
  }, [data.status])
  useEffect(() => {
    if (!isPolling) {
      startPolling(true)
    }
  }, [isPolling])

  return (
    <Backdrop
      sx={{
        background: theme.palette.common.white
      }}
      open={true}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '20px',
          textAlign: 'center'
        }}
      >
        <Typography variant="h1" data-cy="title" marginTop={theme.spacing(48)} marginBottom={theme.spacing(16)}>
          Awaiting {directorName} to complete onboarding...
        </Typography>
        <Box sx={{ position: 'relative' }} id="spinner">
          <CircularProgress
            variant="indeterminate"
            disableShrink
            size={80}
            color="primary"
            sx={{ animationDuration: '600ms' }}
          />
        </Box>
        <Typography
          marginTop={theme.spacing(16)}
          marginBottom={theme.spacing(60)}
          variant="body2"
          data-cy="loader-body"
        >
          {directorName} was sent an e-mail with a link (
          <Link
            component="a"
            variant="caption"
            id="resume-director-flow"
            href={window.location.href.replace('/requester', '')}
          >
            link
          </Link>
          ) to complete onboarding.
          <br />
          Once they complete the process, we will redirect you back to the checkout to complete the purchase.
          <br />
          {returnUrl != null && (
            <b>
              Can't wait?
              <Link component="a" variant="caption" id="return-to-merchant-btn" href={returnUrl}>
                Return to merchant to choose a different payment method.
              </Link>
            </b>
          )}
        </Typography>
        <div style={{ width: '100%' }}>
          <TrustPilotBox />
        </div>
      </div>
    </Backdrop>
  )
}

export default NonDirectorPollingPageContent
