import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import { KriyaLogo } from '../../assets/storageAccountUploadLinks'
import Layout from './Layout'
import Link from '@mui/material/Link'
import React from 'react'
import styled from '@emotion/styled'
import theme from '../../assets/theme'
import Typography from '@mui/material/Typography'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import MenuIcon from '@mui/icons-material/Menu'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import { useBanner } from '../../custom-hooks/useBanner'

const StyledLogoLink = styled(Link)`
  height: 20px;
  width: 83px;
  ${theme.breakpoints.down('sm')} {
    height: 16px;
    width: 67px;
  }
`

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: end;
  ${theme.breakpoints.down('sm')} {
    display: none;
  }
`

const MobileStyledBox = styled(Box)`
  ${theme.breakpoints.up('sm')} {
    display: none;
  }
`

const MobileMenuContentStyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: ${theme.spacing(10)};
`

const AppBarStyledDiv = styled(Box)`
  display: flex;
  justify-content: space-between;
  padding: ${theme.spacing(15, 76, 12)};
  ${theme.breakpoints.down('sm')} {
    padding: ${theme.spacing(10, 12)};
  }
`

const NavigationBar = ({ children }: { children: React.ReactNode }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const { isBannerVisible, BannerComponent } = useBanner()

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget.parentElement?.parentElement?.parentElement ?? null)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Box>
      <AppBar position="static" id="app-bar">
        <AppBarStyledDiv>
          <StyledLogoLink href="https://kriya.co" target="_blank">
            <img src={KriyaLogo} alt="Kriya Logo" />
          </StyledLogoLink>
          <MobileStyledBox>
            <Button
              id="menu-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              variant="navigation"
            >
              <MenuIcon sx={{ color: theme.palette.common.white, fontSize: '24px' }} />
            </Button>
            <Menu
              id="basic-menu"
              className="navbar"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'app-bar'
              }}
            >
              <MobileMenuContentStyledBox>
                <Typography variant="navingationLinkAddOn" id="visit-website-link-add-on">
                  Kriya Finance Limited is a company registered in England and Wales (No. 07330525) (FCA Reference No:
                  750199)
                </Typography>
                <Link
                  href="https://kriya.co"
                  target="_blank"
                  variant="navigationLink"
                  id="visit-website-link"
                  sx={{ paddingTop: theme.spacing(9) }}
                >
                  <Typography variant="navigationLink" sx={{ display: 'flex', alignItems: 'bottom' }}>
                    <span>Visit our website</span>
                    <OpenInNewIcon sx={{ fontSize: '12px', paddingLeft: theme.spacing(3) }} />
                  </Typography>
                </Link>
              </MobileMenuContentStyledBox>
            </Menu>
          </MobileStyledBox>
          <StyledBox>
            <Link href="https://kriya.co" target="_blank" variant="navigationLink" id="visit-website-link">
              <Typography variant="navigationLink" sx={{ display: 'flex', alignItems: 'bottom' }}>
                <span>Visit our website</span>
                <OpenInNewIcon sx={{ fontSize: '12px', paddingLeft: theme.spacing(3) }} />
              </Typography>
            </Link>
            <Typography variant="navingationLinkAddOn" id="visit-website-link-add-on">
              Kriya Finance Limited is a company registered in England and Wales (No. 07330525) (FCA Reference No:
              750199)
            </Typography>
          </StyledBox>
        </AppBarStyledDiv>
      </AppBar>
      {isBannerVisible && BannerComponent}
      <Layout>{children}</Layout>
    </Box>
  )
}

export default NavigationBar
