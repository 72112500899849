import React from 'react'
import { CheckType } from '../../../../types/enums/CheckType'
import { useFetchCheckDetails } from '../../../../custom-hooks/useFetchCheckDetails'
import SelfieCheck from './SelfieCheck'
import { SelfieCheckResponse } from '../../../../types/checks/SelfieCheckResponse'
import { useSaveCheckDetails } from '../../../../custom-hooks/useSaveCheckDetails'
import PageLoading from '../../../../components/loading/PageLoading'

export interface CheckProps {
  onboardingJourneyId: string
  checkType: CheckType
}

export const Check = ({ onboardingJourneyId, checkType }: CheckProps) => {
  const { data, isLoading } = useFetchCheckDetails(onboardingJourneyId, checkType)
  const { mutate: saveCheckDetails } = useSaveCheckDetails({ onboardingJourneyId, checkType })

  if (isLoading) return <PageLoading title="Getting everything ready..." body="Please don't close the window." />

  switch (checkType) {
    case CheckType.Selfie:
      return <SelfieCheck {...(data as SelfieCheckResponse)} onSubmit={saveCheckDetails} />
    default:
      return <></>
  }
}
