import { apiClient } from '../api/apiClient'
import { useQuery, useQueryClient } from 'react-query'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { fetchOnboardingJourneyKey } from './useOnboardingJourneyQuery'
import { OnboardingStatusCheck } from '../types/OnboardingStatusCheck'

export const checkOnboardingStatus = 'fetch-onboarding-status'

export const fetchOnboardingStatusCheck = async (onboardingJourneyId: string): Promise<OnboardingStatusCheck> => {
  const response = await apiClient.get<OnboardingStatusCheck>(`/onboardingStatus/${onboardingJourneyId}`)
  return response.data
}

export const useCheckOnboardingStatus = (onboardingJourneyId: string) => {
  const queryClient = useQueryClient()
  const [start, setStart] = useState(false)
  const navigate = useNavigate()

  const onErrorHandler = () => {
    setStart(false)
    navigate('/error')
  }

  useQuery<OnboardingStatusCheck, Error>(
    [checkOnboardingStatus],
    () => fetchOnboardingStatusCheck(onboardingJourneyId),
    {
      enabled: start,
      refetchInterval: start ? 3000 : false,
      refetchOnWindowFocus: false,
      onSuccess: async (data: OnboardingStatusCheck) => {
        if (data.isOnboardingCompleted) {
          await queryClient.invalidateQueries(fetchOnboardingJourneyKey)
        }
      },
      onError: () => {
        onErrorHandler()
      }
    }
  )

  return { startPolling: setStart, isPolling: start }
}
