import { OnboardingJourney } from '../../types/OnboardingJourney'
import DirectorStepLimitedCompany from './limitedCompany/DirectorStep'
import { useNavigate } from 'react-router-dom'

const DirectorStepContainer = (data: OnboardingJourney) => {
  const navigate = useNavigate()

  const flowTypeToComponentMap = {
    limitedcompany: DirectorStepLimitedCompany
  }

  if (!data.flowType) {
    navigate('/error')
  }

  const flowTypeKey = data.flowType!.toString().toLocaleLowerCase() as keyof typeof flowTypeToComponentMap
  const ComponentToRender = flowTypeToComponentMap[flowTypeKey]

  return <ComponentToRender {...data} />
}

export default DirectorStepContainer
