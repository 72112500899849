import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'

import theme from '../../assets/theme'
import { ContentGroup } from '../../assets/styles/ContentStyles'
import { SoleTraderIdentificationType } from '../../types/enums/SoleTraderIdentificationType'

export type SoleTraderIdentificationProps = {
  soleTraderIdentification?: SoleTraderIdentificationType
  onSoleTraderIdentificationChange: (value: SoleTraderIdentificationType) => void
}

const SoleTraderIdentification = ({
  soleTraderIdentification,
  onSoleTraderIdentificationChange
}: SoleTraderIdentificationProps) => {
  const onChange = (_: React.ChangeEvent<HTMLInputElement>, value: SoleTraderIdentificationType) => {
    onSoleTraderIdentificationChange(value)
  }
  return (
    <>
      <Box sx={{ marginBottom: theme.spacing(10) }}>
        <Typography data-cy="header" variant="caption">
          How do you want to identify yourself?
        </Typography>
        <ContentGroup style={{ rowGap: theme.spacing(8), marginTop: theme.spacing(8) }}>
          <Typography variant="body2" data-cy="content">
            If you have used Kriya PayLater before, please enter the same email or number you registered before.
          </Typography>
        </ContentGroup>
      </Box>
      <Box>
        <RadioGroup
          id="sole-trader-identify-radio-buttons-group"
          aria-labelledby="sole-trader-identify-radio-buttons-group"
          name="sole-trader-identify-radio-buttons-group"
          value={soleTraderIdentification}
          onChange={(_: React.ChangeEvent<HTMLInputElement>, value: string) =>
            onChange(_, value as SoleTraderIdentificationType)
          }
        >
          <FormControlLabel
            value={SoleTraderIdentificationType.Email}
            control={<Radio id="email-soletrader-radio" />}
            label="Email"
          />
          <FormControlLabel
            value={SoleTraderIdentificationType.PhoneNumber}
            control={<Radio id="phone-soletrader-radio" />}
            label="Mobile number"
          />
        </RadioGroup>
      </Box>
    </>
  )
}

export default SoleTraderIdentification
