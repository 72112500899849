import React from 'react'
import Typography from '@mui/material/Typography'
import DialogTitle from '@mui/material/DialogTitle'
import CloseIcon from '@mui/icons-material/Close'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import theme from '../../assets/theme'
import Button from '@mui/material/Button'
import styled from '@emotion/styled'
import useTrackEvent from '../../custom-hooks/useTrackEvent'
import { EventTypes } from '../../types/enums/TrackEventType'

const StyledButton = styled(Button)`
  margin-left: ${theme.spacing(6)};
  font-weight: ${theme.typography.body2semibold.fontWeight};
  ${theme.breakpoints.down('sm')} {
    margin-left: 0;
  }
`

const StyledDialog = styled(Dialog)`
  & .MuiDialog-paper {
    width: 464px;
    height: 228px;
    padding: ${theme.spacing(16, 12)};
    ${theme.breakpoints.down('sm')} {
      padding: ${theme.spacing(12)};
      width: 342px;
      height: 276px;
    }
  }
  & .MuiDialogActions-root {
    padding: ${theme.spacing(10, 0, 0)};
  }
`

export interface ReturnToMarketplaceDialogProps {
  marketplace: string
  disabled: boolean
  redirectUrl?: string
}

function ReturnToMarketplaceDialog(props: ReturnToMarketplaceDialogProps) {
  const trackEvent = useTrackEvent()
  const [open, setOpen] = React.useState(false)
  const { marketplace } = props

  const handleDialogOpen = () => {
    trackEvent(EventTypes.Shared.ReturnToMerchant.CLICK_OPEN)
    setOpen(true)
  }

  const handleDialogClose = () => {
    trackEvent(EventTypes.Shared.ReturnToMerchant.CLICK_CANCEL)
    setOpen(false)
  }

  const handleLeaveKriya = () => {
    trackEvent(EventTypes.Shared.ReturnToMerchant.CLICK_CONFIRM)
    window.location.replace(props.redirectUrl as string)
  }

  return (
    <>
      <StyledButton variant="text" onClick={handleDialogOpen} disabled={props.disabled} id="return-to-merchant-btn">
        Return to {marketplace}
      </StyledButton>
      <StyledDialog
        onClose={handleDialogClose}
        open={open}
        role="dialog"
        aria-labelledby="return-to-marketplace-dialog-title"
        aria-describedby="return-to-marketplace-content"
      >
        <DialogTitle sx={{ padding: theme.spacing(0, 0, 2, 0) }} id="return-to-marketplace-dialog-title">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h1">Leaving Kriya?</Typography>
            <div>
              <IconButton onClick={handleDialogClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </div>
          </div>
        </DialogTitle>
        <DialogContent id="return-to-marketplace-content" sx={{ padding: 0 }}>
          <Typography
            variant="body2"
            color={theme.palette.common.textGrey700}
            sx={{
              pt: {
                xs: theme.spacing(10),
                sm: theme.spacing(8)
              }
            }}
          >
            Should you exit Kriya at this moment, any edits you've made won't be preserved.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="dialog" onClick={handleDialogClose}>
            Stay and continue
          </Button>
          <Button variant="outlined" sx={{ minWidth: '140px' }} onClick={handleLeaveKriya}>
            Return to {marketplace}
          </Button>
        </DialogActions>
      </StyledDialog>
    </>
  )
}

export default ReturnToMarketplaceDialog
