import createTheme, { Theme } from '@mui/material/styles/createTheme'

import { Components } from '@mui/material/styles'

export const eligibleSuccessColor = 'rgba(25, 135, 84, 1)'
const font = "'Matter', 'Roboto Condensed', sans-serif"
const primaryMain = '#00283C'
const backgroundGrey100 = '#F0F0F0'
const colorOnDisabled = '#C2C2C2'
const uiErrorRedColor = '#DC3545'
const textGrey900 = '#090909'
const backgroundPrimaryGrey = '#DCD7D7'
const textGrey600 = '#606060'
const white = '#FFFFFF'

let theme = createTheme({
  spacing: 2, //Add every spacing as a multiple of 2. Eg: If you have to set padding: 8px, write it as theme.spacing(4) i.e. 4*2 = 8px
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536
    }
  },
  palette: {
    primary: {
      main: primaryMain
    },
    common: {
      buttonBackgroundHover: '#32627F',
      buttonFocusStroke: '#00CF3B',
      backgroundActive: '#3F7090',
      backgroundWhite: '#FFFFFF',
      buttonColorOnDisabled: colorOnDisabled,
      backgroundGrey100: backgroundGrey100,
      visitedLink: '#7100E1',
      shadow: 'rgba(0, 40, 60, 0.08)',
      backgroundGrey: '#DEDEDE',
      textGrey900: textGrey900,
      textGrey700: '#3C3C3C',
      textGrey600: textGrey600,
      grey10: '#FAFAFA',
      info: '#073DC9',
      navigationLinkActive: '#B8D6EB',
      navigationLinkVisited: '#EDBDFE',
      menuBackground: '#003B59',
      linkHover: '#0A58CA'
    }
  },
  typography: {
    fontFamily: font,
    h1: {
      fontSize: '26px',
      fontWeight: '500',
      lineHeight: '36px'
    },
    h2: {
      fontSize: '16px',
      fontWeight: '600',
      lineHeight: '24px',
      color: textGrey900
    },
    h3: {
      fontSize: '20px',
      fontWeight: '500',
      lineHeight: '28px',
      color: textGrey900
    },
    body2: {
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '24px',
      color: textGrey900
    },
    body2semibold: {
      fontFamily: font,
      fontSize: '16px',
      fontWeight: '600',
      lineHeight: '24px',
      color: textGrey900
    },
    body3: {
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '28px'
    },
    subtitle1: {
      fontSize: '14px',
      fontWeight: '600',
      lineHeight: '20px',
      color: textGrey900
    },
    errorPageHeader: {
      fontFamily: font,
      fontSize: '32px',
      fontWeight: '500',
      lineHeight: '40px',
      color: uiErrorRedColor
    },
    errorPageContent: {
      fontFamily: font,
      fontSize: '20px',
      fontWeight: '400',
      lineHeight: '28px',
      color: textGrey900
    },
    errorText: {
      fontSize: '12px',
      fontWeight: '700',
      lineHeight: '16px',
      color: uiErrorRedColor
    },
    inputLabel: {
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '18px',
      color: textGrey900
    },
    inputText: {
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '24px'
    },
    progressBarCircleLabel: {
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '18px',
      fontFamily: 'Matter',
      letterSpacing: '0.16px'
    },
    label: {
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '16px'
    },
    caption: {
      fontSize: '16px',
      fontWeight: '600',
      lineHeight: '22px',
      color: textGrey900
    },
    subtitle2: {
      fontSize: '20px',
      fontWeight: '600',
      lineHeight: '28px',
      color: eligibleSuccessColor
    },
    h4: {
      fontSize: '20px',
      fontWeight: '500',
      lineHeight: '28px',
      color: textGrey900
    },
    PageLoadingHeader: {
      fontSize: '26px',
      fontWeight: '500',
      lineHeight: '36px'
    },
    helperText2: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '18px',
      letterSpacing: '0.16px',
      color: textGrey600,
      fontFamily: font
    },
    navigationLink: {
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '12px'
    },
    navingationLinkAddOn: {
      fontSize: '10px',
      fontWeight: 400,
      lineHeight: '20px',
      color: white
    }
  }
})

const components: Components<Theme> = {
  MuiAppBar: {
    styleOverrides: {
      root: {
        backgroundColor: theme.palette.primary.main,
        height: '80px',
        [theme.breakpoints.down('sm')]: {
          height: '64px'
        }
      }
    }
  },
  MuiCheckbox: {
    defaultProps: {
      disableRipple: true
    }
  },
  MuiButton: {
    defaultProps: {
      disableTouchRipple: true,
      disableFocusRipple: true
    },
    styleOverrides: {
      root: ({ ownerState }) => ({
        textTransform: 'none',
        ...(ownerState.variant !== 'navigation' && {
          ':hover': {
            background: theme.palette.common.buttonBackgroundHover,
            color: theme.palette.common.white
          },
          ':focus': {
            background: theme.palette.primary.main,
            boxShadow: `0 0 0 3px ${theme.palette.common.buttonFocusStroke}`,
            color: theme.palette.common.white
          },
          ':active': {
            background: theme.palette.common.backgroundActive,
            color: theme.palette.common.white
          }
        }),
        ...(ownerState.variant === 'navigation' && {
          padding: 0
        }),
        ...(ownerState.variant === 'contained' && {
          fontWeight: 600,
          fontSize: '16px',
          lineHeight: '24px',
          borderRadius: '100px',
          width: 'max-content',
          height: '48px',
          padding: theme.spacing(6, 12),
          ':disabled': {
            color: theme.palette.common.buttonColorOnDisabled,
            background: theme.palette.common.backgroundGrey100,
            boxShadow: `0 0 0 3px ${theme.palette.common.backgroundGrey100}`
          },
          [theme.breakpoints.down('sm')]: {
            width: '100%',
            padding: theme.spacing(4),
            marginBottom: theme.spacing(8),
            fontSize: '14px',
            lineHeight: '20px',
            height: '44px'
          }
        }),
        ...(ownerState.variant === 'text' && {
          color: textGrey900,
          fontSize: '16px',
          lineHeight: '24px',
          fontWeight: 600,
          boxShadow: '0 0 0 3px transparent',
          padding: theme.spacing(4, 5),
          borderRadius: '6px',
          textDecoration: 'underline',
          height: '48px',
          ':hover': {
            background: theme.palette.common.backgroundGrey100,
            textDecoration: 'underline'
          },
          ':focus': {
            boxShadow: `0 0 0 3px ${theme.palette.common.buttonFocusStroke}`,
            background: theme.palette.common.white,
            outline: 'none'
          },
          ':active': {
            background: backgroundPrimaryGrey,
            boxShadow: '0 0 0 3px transparent'
          },
          ':disabled': {
            color: theme.palette.common.buttonColorOnDisabled,
            background: theme.palette.common.backgroundWhite
          },
          [theme.breakpoints.down('sm')]: {
            width: '100%',
            padding: theme.spacing(4),
            fontSize: '14px',
            lineHeight: '20px',
            height: '44px'
          }
        }),
        ...(ownerState.variant === 'component' && {
          color: textGrey900,
          fontSize: '16px',
          lineHeight: '24px',
          fontWeight: 400,
          width: 'fit-content',
          padding: theme.spacing(0, 0),
          textDecoration: 'underline',
          height: '48px',
          ':hover': {
            background: theme.palette.common.white,
            textDecoration: 'underline',
            color: textGrey900
          },
          ':focus': {
            boxShadow: 'none',
            background: theme.palette.common.white,
            outline: 'none'
          },
          ':active': {
            background: theme.palette.common.white,
            boxShadow: '0 0 0 3px transparent'
          }
        }),
        ...((ownerState.variant === 'outlined' || ownerState.variant === 'dialog') && {
          fontSize: '14px',
          lineHeight: '20px',
          fontWeight: 400,
          height: '36px',
          padding: theme.spacing(4, 6),
          borderRadius: '100px',
          ':hover': {
            background: theme.palette.common.buttonBackgroundHover,
            color: theme.palette.common.white
          },
          ':focus': {
            background: theme.palette.primary.main,
            boxShadow: `0 0 0 3px ${theme.palette.common.buttonFocusStroke}`,
            color: theme.palette.common.white
          },
          ':active': {
            background: theme.palette.common.backgroundActive,
            color: theme.palette.common.white
          },
          [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(4),
            fontSize: '14px',
            lineHeight: '20px'
          }
        }),
        ...(ownerState.variant === 'dialog' && {
          background: theme.palette.primary.main,
          border: '3px solid transparent',
          color: theme.palette.common.white,
          ':disabled': {
            color: theme.palette.common.buttonColorOnDisabled,
            background: theme.palette.common.backgroundGrey100,
            boxShadow: `0 0 0 3px ${theme.palette.common.backgroundGrey100}`
          }
        }),
        ...(ownerState.variant === 'outlined' && {
          boxSizing: 'border-box',
          minWidth: '110px',
          background: theme.palette.common.backgroundWhite,
          border: `1px solid ${theme.palette.primary.main}`,
          ':disabled': {
            color: theme.palette.common.buttonColorOnDisabled,
            border: `1px solid ${theme.palette.common.buttonColorOnDisabled}`,
            boxShadow: `0 0 0 3px ${theme.palette.common.backgroundWhite}`,
            background: theme.palette.common.backgroundWhite
          }
        })
      })
    }
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        fontSize: theme.typography.inputLabel.fontSize,
        lineHeight: theme.typography.inputLabel.lineHeight,
        color: theme.typography.inputLabel.color,
        '&.Mui-focused': {
          color: theme.typography.inputLabel.color
        }
      }
    }
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        'label + &': {
          marginTop: theme.spacing(2)
        },
        '& .MuiInputBase-input': {
          padding: theme.spacing(6)
        }
      }
    }
  },
  MuiAutocomplete: {
    styleOverrides: {
      root: {
        'label + &': {
          marginTop: theme.spacing(2)
        }
      },
      inputRoot: {
        padding: theme.spacing(6),
        '& .MuiAutocomplete-input': {
          padding: theme.spacing(0, 2)
        }
      }
    }
  },
  MuiAccordion: {
    defaultProps: {
      disableGutters: true,
      square: true,
      elevation: 0
    },
    styleOverrides: {
      root: {
        borderTop: `1px solid ${theme.palette.divider}`,
        borderBottom: `1px solid ${theme.palette.divider}`,
        ':not(:last-child)': {
          borderBottom: 0
        },
        ':before': {
          display: 'none'
        }
      }
    }
  },
  MuiAccordionSummary: {
    styleOverrides: {
      root: {
        padding: theme.spacing(5),
        border: '2px solid transparent',
        '& .MuiAccordionSummary-content': {
          margin: theme.spacing(0)
        },
        '& .MuiAccordionSummary-expandIconWrapper': {
          color: theme.palette.common.textGrey900
        },
        ':hover': {
          background: theme.palette.common.backgroundGrey
        },
        ':focus-within': {
          background: theme.palette.common.white,
          border: `2px solid ${theme.palette.common.buttonFocusStroke}`
        },
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(3, 5)
        }
      }
    }
  },
  MuiAccordionDetails: {
    styleOverrides: {
      root: {
        padding: theme.spacing(3, 5, 7, 5)
      }
    }
  },
  MuiLink: {
    styleOverrides: {
      root: ({ ownerState }) => ({
        border: '2px solid transparent',
        ':focus': {
          border: `2px solid ${theme.palette.common.buttonFocusStroke}`,
          outline: 'none',
          borderRadius: '4px'
        },
        ...(ownerState.variant !== 'navigationLink' && {
          color: 'textGrey900',
          textDecoration: 'underline',
          ':active': {
            color: theme.palette.primary.main
          },
          '&:focus': {
            color: theme.palette.primary.main
          },
          ':visited': {
            color: theme.palette.common.visitedLink
          }
        }),
        ...(ownerState.variant === 'navigationLink' && {
          textDecoration: 'none',
          color: theme.palette.common.white,
          ':active': {
            color: theme.palette.common.navigationLinkActive
          },
          '&:focus': {
            color: theme.palette.common.navigationLinkActive
          },
          ':visited': {
            color: theme.palette.common.navigationLinkVisited
          }
        })
      })
    }
  },
  MuiDialog: {
    styleOverrides: {
      root: {
        '& .MuiDialog-paper': {
          borderRadius: '8px',
          boxSizing: 'border-box',
          [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(8)
          }
        }
      }
    }
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        [theme.breakpoints.down('sm')]: {
          flexDirection: 'column',
          justifyContent: 'center'
        },
        '& button': {
          [theme.breakpoints.down('sm')]: {
            width: '100%'
          }
        },
        '& button:nth-child(2)': {
          [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(4)
          }
        }
      }
    }
  },
  MuiIconButton: {
    defaultProps: {
      disableRipple: true
    },
    styleOverrides: {
      root: {
        color: textGrey900
      }
    }
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        '.navbar > &': {
          backgroundColor: theme.palette.common.menuBackground,
          minWidth: '100% !important',
          height: '100%',
          maxHeight: 'calc(100% - 64px) !important',
          maxWidth: '100% !important',
          borderRadius: 0,
          left: '0px !important',
          top: '64px !important'
        }
      }
    }
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        maxWidth: '445px',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
        letterSpacing: '0.16px',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.backgroundWhite,
        alignItems: 'center',
        justifyContent: 'center',
        padding: `${theme.spacing(1)} ${theme.spacing(2)}`
      }
    }
  }
}

theme = createTheme(theme, {
  components,
  typography: {
    errorPageHeader: {
      [theme.breakpoints.down('sm')]: {
        fontSize: '20px',
        lineHeight: '28px'
      }
    },
    errorPageContent: {
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '0.16px'
      }
    },
    PageLoadingHeader: {
      [theme.breakpoints.down('sm')]: {
        fontSize: '16px',
        lineHeight: '22px',
        fontWeight: '600',
        letterSpacing: '0.16px'
      }
    },
    body2: {
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
        lineHeight: '20px',
        fontWeight: '400',
        letterSpacing: '0.16px'
      }
    },
    body2semibold: {
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
        lineHeight: '20px'
      }
    },
    helperText2: {
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
        fontWeight: 600,
        lineHeight: '16px',
        letterSpacing: '0.32px'
      }
    },
    h4: {
      [theme.breakpoints.down('sm')]: {
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '22px'
      }
    },
    h1: {
      [theme.breakpoints.down('sm')]: {
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '24px'
      }
    }
  }
})
export default theme
