import SegmentWrapper from '../utils/metrics/segmentWindow'
import { useCallback, useEffect } from 'react'
import { PageViewProperties } from '../types/PageViewProperties'

const useTrackVirtualPageView = (page?: string, properties?: PageViewProperties) => {
  const trackPage = useCallback((page: string, properties?: PageViewProperties) => {
    SegmentWrapper.virtualPageView(page, properties)
  }, [])

  useEffect(() => {
    if (page) {
      trackPage(page, properties)
    }
  }, [page, properties, trackPage])

  return trackPage
}

export default useTrackVirtualPageView
