import React from 'react'
import { OnboardingJourney } from '../../../../types/OnboardingJourney'
import { useUpdateOnboardingJourneyStatus } from '../../../../custom-hooks/useUpdateOnboardingJourneyStatus'
import { Controller, useForm } from 'react-hook-form'
import { Content, ContentGroup } from '../../../../assets/styles/ContentStyles'
import Logo from '../../../../components/Logo'
import Typography from '@mui/material/Typography'
import theme from '../../../../assets/theme'
import { matchIsValidTel, MuiTelInput } from 'mui-tel-input'
import { defaultCountryCode } from '../../../../consts'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import ErrorText from '../../../error/FormErrors'
import { StyledButtonsContainer } from '../../../../components/layout/Container'
import ActionButton from '../../../../components/ActionButton'
import SignedTermsCheck from '../../checkWizard/components/SignedTermsCheck'
import { CheckType } from '../../../../types/enums/CheckType'
import Stack from '@mui/material/Stack'
import { useSaveCheckDetails } from '../../../../custom-hooks/useSaveCheckDetails'
import useTrackVirtualPageView from '../../../../custom-hooks/useVirtualPageView'
import { VirtualPage } from '../../../../types/enums/VirtualPage'

export interface DirectorContactForm {
  phoneNumber?: string
  termsAndConditions?: boolean
}

const DirectorLimitedCompanyStartPage = (data: OnboardingJourney) => {
  useTrackVirtualPageView(VirtualPage.DIRECTOR_START)
  const {
    mutate: updateOnboardingJourneyStatusMutation,
    isLoading: isMutationLoading,
    mutateAsync: updateOnboardingJourneyStatusAsync
  } = useUpdateOnboardingJourneyStatus({
    onboardingJourneyId: data.id
  })

  const { mutate: saveCheckMutation, isLoading: isCheckUpdateInProgress } = useSaveCheckDetails({
    onboardingJourneyId: data.id,
    checkType: CheckType.SignedTerms
  })

  const form = useForm<DirectorContactForm>({
    mode: 'onChange'
  })

  const { control, handleSubmit, setValue } = form

  const hasCheckOfType = (checkType: CheckType) => (data.checks?.filter((r) => r.type === checkType).length || 0) > 0

  const onSubmit = (formValues: DirectorContactForm) => {
    if (!hasCheckOfType(CheckType.SignedTerms)) {
      updateOnboardingJourneyStatusMutation({
        phoneNumber: formValues.phoneNumber
      })
    } else {
      void updateOnboardingJourneyStatusAsync({
        phoneNumber: formValues.phoneNumber
      }).then(() => saveCheckMutation({}))
    }
  }

  return (
    <Content>
      {data.merchant?.logoUri ? <Logo logoUri={data.merchant.logoUri} /> : <br />}
      <Typography variant="h2" data-cy="title" marginTop={theme.spacing(16)}>
        Welcome to Kriya
      </Typography>

      <ContentGroup>
        <Typography variant="body2" data-cy="content">
          Hi {data.director.fullName},
        </Typography>

        <Typography variant="body2" data-cy="congratulatory-content">
          We'll help you with the onboarding process. Once complete, you'll receive a confirmation email and can begin
          using Kriya PayLater for your purchases.
        </Typography>
      </ContentGroup>

      <form onSubmit={handleSubmit(onSubmit)} id="director-form" style={{ rowGap: theme.spacing(24), display: 'grid' }}>
        <Controller
          control={control}
          rules={{
            required: true,
            validate: (value) => !value || matchIsValidTel(value, { onlyCountries: [defaultCountryCode] })
          }}
          render={({ field, fieldState }) => (
            <Box>
              <FormControl fullWidth>
                <FormLabel htmlFor="phoneNumber">Enter your mobile</FormLabel>
                <MuiTelInput
                  {...field}
                  sx={{ width: '100%' }}
                  data-cy="phoneNumber"
                  defaultCountry={defaultCountryCode}
                  onlyCountries={[defaultCountryCode]}
                  disableDropdown={true}
                  error={fieldState.invalid}
                />
              </FormControl>
              {fieldState.invalid && <ErrorText id={field.name}>Please enter a valid mobile phone number</ErrorText>}
            </Box>
          )}
          name="phoneNumber"
        />

        {hasCheckOfType(CheckType.SignedTerms) && (
          <Controller
            control={control}
            name="termsAndConditions"
            rules={{
              required: true
            }}
            render={({ field }) => (
              <Box>
                <FormControl fullWidth>
                  <SignedTermsCheck
                    field={field}
                    setValue={(value) => setValue('termsAndConditions', value, { shouldValidate: true })}
                  />
                </FormControl>
              </Box>
            )}
          />
        )}

        <StyledButtonsContainer style={{ marginTop: 0 }}>
          <Stack>
            {data.director.fullName ? (
              <ActionButton
                id="continue-button"
                loading={isMutationLoading || isCheckUpdateInProgress}
                disabled={!form.formState.isValid || isMutationLoading || isCheckUpdateInProgress}
                style={{ marginBottom: theme.spacing(8) }}
              >
                Continue
              </ActionButton>
            ) : null}
            <ContentGroup>
              <Typography variant="subtitle1" data-cy="note-content">
                Please note that this page is for {data.director.fullName}'s use only.
              </Typography>
            </ContentGroup>
          </Stack>
        </StyledButtonsContainer>
      </form>
    </Content>
  )
}

export default DirectorLimitedCompanyStartPage
