const routes = {
  root: '/',
  home: '/:merchantId/:merchantCompanyIdentifier',
  requester: '/:merchantId/:merchantCompanyIdentifier/requester',
  error: '/error',
  notFound: '*',
  inReview: '/:merchantId/:merchantCompanyIdentifier/in-review',
  rejected: '/:merchantId/:merchantCompanyIdentifier/rejected',
  phoneNumberDuplicate: '/:merchantId/:merchantCompanyIdentifier/phone-number-duplicate'
}

export default routes
