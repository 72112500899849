import React, { createContext, useContext, useState, ReactNode } from 'react'

interface BannerContextType {
  isBannerVisible: boolean
  showBanner: (component: ReactNode) => void
  hideBanner: () => void
  BannerComponent: ReactNode | null
}

const BannerContext = createContext<BannerContextType | undefined>(undefined)

export const BannerProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isBannerVisible, setBannerVisible] = useState(false)
  const [BannerComponent, setBannerComponent] = useState<ReactNode | null>(null)

  const showBanner = (component: ReactNode) => {
    if (!isBannerVisible) {
      setBannerComponent(component)
      setBannerVisible(true)
    }
  }

  const hideBanner = () => {
    if (isBannerVisible) {
      setBannerVisible(false)
      setBannerComponent(null)
    }
  }

  return (
    <BannerContext.Provider value={{ isBannerVisible, showBanner, hideBanner, BannerComponent }}>
      {children}
    </BannerContext.Provider>
  )
}

export const useBanner = () => {
  const context = useContext(BannerContext)
  if (!context) {
    throw new Error('useBanner must be used within a BannerProvider')
  }
  return context
}
