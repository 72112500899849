import Typography from '@mui/material/Typography'
import React, { ReactElement } from 'react'
import Link from '@mui/material/Link'
import styled from '@emotion/styled'
import theme from '../../assets/theme'
import useTrackEvent from '../../custom-hooks/useTrackEvent'
import { EventTypes } from '../../types/enums/TrackEventType'
import AccordionPanel from '../accordion/Accordion'

type FaqParams = {
  marketplaceName: string
}

const Faq = ({ marketplaceName }: FaqParams) => {
  const trackEvent = useTrackEvent()
  const items: { title: string; content?: ReactElement }[] = [
    {
      title: `Is my business eligible?`,
      content: (
        <Typography variant="body1">
          Limited businesses, sole traders and government organisations are eligible. Your business must be active, have
          been trading for a minimum of 12 months, and have filed up-to-date business accounts and returns with HMRC &
          Companies House. 18+. No outstanding CCJs, arrears or defaults. UK only.
        </Typography>
      )
    },
    {
      title: 'Does using Kriya affect my credit score?',
      content: (
        <Typography variant="body1">
          To assess your eligibility for using Kriya PayLater, a soft credit search is performed against your business,
          or if you’re a sole trader or partnership, you as an individual. A soft credit check will not impact your
          credit score. If you are eligible, the first time you make a purchase with Kriya PayLater, a hard search will
          be made. This will only happen the first time you use the service. This search will appear on your credit
          report. Multiple hard searches in a short period of time may affect your credit score.
        </Typography>
      )
    },
    {
      title: 'How does it work?',
      content: (
        <Typography variant="body1">
          <p>
            Choosing to pay using Kriya PayLater means you can defer paying for goods and services until a later date.
            The way this works is that the cost of your order is covered upfront by Kriya, and you have extended payment
            terms to repay the balance. Kriya has provided a bank account in {marketplaceName}'s name specifically for
            PayLater orders. Once payment for your order is due, you’ll be required to pay the balance into this bank
            account. The details will be on your {marketplaceName} invoice. Please note, paying {marketplaceName} back
            directly could mean delays in reinstating your spending limit.
          </p>
          <p>
            {marketplaceName} remains fully responsible for the quality and delivery of the goods or services as per
            their terms and conditions. Any disputes or issues with respect to the goods or services ordered by you, you
            should contact {marketplaceName}. In the event that you are eligible for reimbursement of any payments under
            the supplier’s terms and conditions, such reimbursements will be reflected in a corresponding reduction in
            your remaining scheduled payments.
          </p>
        </Typography>
      )
    },
    {
      title: 'What does it cost?',
      content: (
        <Typography variant="body1">
          <p>
            Choosing to pay with Kriya PayLater is free. There are no transaction fees involved and no interest charged.
            However, if you are overdue in paying your invoice you may be subject to a late payment charge. Late
            payments can also negatively impact your Kriya PayLater spending limit.
          </p>
        </Typography>
      )
    },
    {
      title: 'What if I miss the due date?',
      content: (
        <Typography variant="body1">
          <p>
            If you miss the due date, you will receive a reminder email. Please note that late payment fees may apply if
            invoices remain overdue for an extended period.
          </p>
        </Typography>
      )
    },
    {
      title: 'What if I have questions about my payment?',
      content: (
        <Typography variant="body1">
          <p>
            Please get in touch via{' '}
            <Link variant="body1" href="mailto:payments@kriya.co">
              payments@kriya.co
            </Link>{' '}
            if you have any questions about your payment.
          </p>
        </Typography>
      )
    }
  ]

  const StyledHeader = styled(Typography)`
    padding: ${theme.spacing(8, 0, 8, 0)};
  `

  const handleFaqItemSelected = (panelTitle: string, isExpanded: boolean) => {
    if (!isExpanded) {
      return
    }

    trackEvent(EventTypes.FAQ.CLICK_ITEM, {
      itemSelected: panelTitle
    })
  }

  return (
    <>
      <StyledHeader>
        <Typography data-cy="faq-title" variant="h1">
          FAQ
        </Typography>
      </StyledHeader>

      <AccordionPanel onPanelSelect={handleFaqItemSelected} items={items} data-cy="kriya-faq" />
    </>
  )
}

export default Faq
