const stagingDomain = '.kriya.dev'
const productionDomain = '.kriya.co'

export const cookieNames = {
  isEmployee: 'kriya_employee',
  analyticsUid: 'k_ajs_uid'
}

export const getCookieDomain = (): string | undefined => {
  const hostname = window.location.hostname

  if (hostname.endsWith(stagingDomain)) {
    return stagingDomain
  } else if (hostname.endsWith(productionDomain)) {
    return productionDomain
  } else {
    return undefined
  }
}
