import { useMutation, useQueryClient } from 'react-query'
import { apiClient } from '../api/apiClient'
import { fetchOnboardingJourneyKey } from './useOnboardingJourneyQuery'
import routes from '../routes'
import { useNavigate } from 'react-router-dom'
import { CheckType } from '../types/enums/CheckType'

export const saveCheckDetails = async (onboardingJourneyId: string, checkType: CheckType, checkDetails: {}) => {
  await apiClient.put(`checks/${onboardingJourneyId}/${checkType}`, checkDetails)
}

export const useSaveCheckDetails = ({
  onboardingJourneyId,
  checkType,
  onError
}: {
  onboardingJourneyId: string
  checkType: CheckType
  onError?: () => void
}) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { mutate, isLoading, mutateAsync } = useMutation(
    async (checkDetails: {}) => {
      await saveCheckDetails(onboardingJourneyId, checkType, checkDetails)
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(fetchOnboardingJourneyKey)
      },
      onError: () => {
        if (onError) {
          onError()
        } else {
          navigate(routes.error)
        }
      }
    }
  )

  return { mutate, isLoading, mutateAsync }
}
