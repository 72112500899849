export enum OnboardingJourneyStatus {
  New = 'New',
  CompanyCheckIsRequested = 'CompanyCheckIsRequested',
  CompanyCheckHasCompletedWithEligible = 'CompanyCheckHasCompletedWithEligible',
  CompanyCheckHasCompletedWithManualCheck = 'CompanyCheckHasCompletedWithManualCheck',
  OnboardingJourneyIsSuccessfullyCompleted = 'OnboardingJourneyIsSuccessfullyCompleted',
  ModularChecksAreInitialised = 'ModularChecksAreInitialized',
  ModularChecksAreInProgress = 'ModularChecksAreInProgress',
  ModularChecksShouldBeReRequested = 'ModularChecksShouldBeReRequested',
  ModularChecksAreInManualReview = 'ModularChecksAreInManualReview',
  ModularChecksHaveFailed = 'ModularChecksHaveFailed'
}

export const CustomerDetailsStatuses = [OnboardingJourneyStatus.New, OnboardingJourneyStatus.CompanyCheckIsRequested]
