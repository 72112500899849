import SegmentWrapper from '../utils/metrics/segmentWindow'
import { OnboardingJourney } from '../types/OnboardingJourney'
import { useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { cookieNames } from '../utils/cookieUtils'

const useIdentify = (data: OnboardingJourney | undefined) => {
  const [cookies] = useCookies([cookieNames.analyticsUid, cookieNames.isEmployee])
  useEffect(() => {
    if (data === undefined) return

    const isEmployee: boolean = (cookies[cookieNames.isEmployee] as string) !== undefined
    const userId: string | null = (cookies[cookieNames.analyticsUid] as string) || null

    SegmentWrapper.identify(userId, {
      isEmployee: isEmployee,
      merchant: data.merchant?.name || 'Unknown',
      buyerId: data.company?.kriyaCompanyIdentifier,
      email: data.requester?.email,
      name: data.requester?.email
    })
  }, [data])
}

export default useIdentify
