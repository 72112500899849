export enum VirtualPage {
  COMPANY_SELECTION = 'Onboarding - Company Information',
  SOLE_TRADER_DETAILS = 'Onboarding - Sole Trader Details',
  SOLE_TRADER_ELIGIBLE = 'Onboarding - Sole Trader Eligible',
  SOLE_TRADER_CONFLICT = 'Onboarding - Sole Trader Conflict',
  DIRECTOR_SELECTION = 'Onboarding - Limited Company Director Selection',
  DIRECTOR_SELECTED = 'Onboarding - Limited Company Director Selected',
  DIRECTOR_START = 'Onboarding - Limited Company Director Start',
  NON_DIRECTOR_POLLING = 'Onboarding - Non Director Polling',
  RISK_ELIGIBILITY_PROCESSING = 'Onboarding - Risk Eligibility Processing',
  RISK_REJECTED = 'Onboarding - Risk Rejected',
  RISK_REVIEW = 'Onboarding - Risk Review',
  SELFIE_CHECK = 'Onboarding - Selfie Check',
  CHECKS_PROCESSING = 'Onboarding - Checks Processing',
  CHECKS_REVIEW = 'Onboarding - Checks Review',
  CHECKS_FAILED = 'Onboarding - Checks Failed',
  CHECKS_PASSED = 'Onboarding - Checks Passed',
  INVALID_LINK = 'Onboarding - Invalid Link',
  UNEXPECTED_ERROR = 'Onboarding - Unexpected Error'
}
