import { useMutation, useQueryClient } from 'react-query'

import { OnboardingJourneyStatus } from '../types/enums/OnboardingJourneyStatus'
import { UpdateOnboardingJourneyRequest } from '../types/UpdateOnboardingJourneyRequest'
import { UpdateOnboardingJourneyResponse } from '../types/UpdateOnboardingJourneyResponse'
import { apiClient } from '../api/apiClient'
import { fetchOnboardingJourneyKey } from './useOnboardingJourneyQuery'
import routes from '../routes'
import { useNavigate } from 'react-router-dom'

export const updateOnboardingJourneyDetails = async (
  onboardingJourneyId: string,
  onboardingJourneyInfo: UpdateOnboardingJourneyRequest
): Promise<UpdateOnboardingJourneyResponse> => {
  const response = await apiClient.put<UpdateOnboardingJourneyResponse>(`${onboardingJourneyId}`, {
    companyId: onboardingJourneyInfo.companyId,
    requester: {
      fullName: onboardingJourneyInfo.requester!.fullName,
      email: onboardingJourneyInfo.requester!.email,
      phoneNumber: onboardingJourneyInfo.requester!.phoneNumber
    },
    director: {
      fullName: onboardingJourneyInfo.director?.fullName,
      email: onboardingJourneyInfo.director?.email,
      phoneNumber: onboardingJourneyInfo.director?.phoneNumber
    }
  })
  return response.data
}

export const useUpdateOnboardingJourney = ({
  onboardingJourneyId,
  onSuccess,
  onError
}: {
  onboardingJourneyId: string
  onSuccess?: (data: UpdateOnboardingJourneyResponse) => void
  onError?: () => void
}) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation(
    async (restDirectorProperties: UpdateOnboardingJourneyRequest): Promise<UpdateOnboardingJourneyResponse> => {
      const data = await updateOnboardingJourneyDetails(onboardingJourneyId, restDirectorProperties)
      return data
    },
    {
      onSuccess: async (data) => {
        if (data.status !== OnboardingJourneyStatus.ModularChecksAreInitialised) {
          await queryClient.invalidateQueries(fetchOnboardingJourneyKey)
        }
        if (onSuccess) {
          onSuccess(data)
        }
      },
      onError: () => {
        if (onError) {
          onError()
        } else {
          navigate(routes.error)
        }
      }
    }
  )

  return { mutate, isLoading }
}
