import { FieldError, FieldValues, Path, UseFormGetValues, UseFormRegister } from 'react-hook-form'
import ErrorText from '../pages/error/FormErrors'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import React from 'react'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import ReadOnlyField from './ReadOnlyField'
import { IMaskMixin } from 'react-imask'

interface BaseProps<T extends FieldValues> {
  register: UseFormRegister<T>
  name: Path<T>
  label: string
  optional?: boolean
  validation?: { [key: string]: string | boolean | {} }
  error?: FieldError
  placeholder?: string
  showErrorIcon?: boolean
  disabled?: boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  startAdornment?: React.ReactNode
  readonly?: boolean
  mask?: string
}

type Props<T extends FieldValues> = BaseProps<T> &
  (BaseProps<T>['readonly'] extends true ? { getValues: UseFormGetValues<T> } : { getValues?: UseFormGetValues<T> })

const MaskedInput = IMaskMixin(({ inputRef, ...props }) => {
  const { mask, size, color, ...textFieldProps } = props

  return <TextField {...textFieldProps} inputRef={inputRef} />
})

const TextInput: <T extends FieldValues>(props: Props<T>) => JSX.Element = ({
  register,
  getValues,
  name,
  label,
  validation = {},
  error,
  placeholder = '',
  optional,
  showErrorIcon,
  disabled = false,
  startAdornment,
  readonly = false,
  mask
}) => {
  const { ref, onChange: formOnChange, onBlur } = register(name, validation)

  if (readonly) {
    return <ReadOnlyField name={name} label={label} getValues={getValues!} />
  }

  return (
    <FormControl>
      <FormLabel htmlFor={name}>
        {optional ? (
          <>
            {label} <Typography variant="inputLabel">(optional)</Typography>{' '}
          </>
        ) : (
          label
        )}
      </FormLabel>

      {mask ? (
        <MaskedInput
          id={name}
          data-cy={name}
          mask={mask}
          placeholder={placeholder}
          inputRef={ref}
          onAccept={(value: any) => formOnChange({ target: { value, name } })}
          onBlur={onBlur}
          disabled={disabled}
          error={!!error}
          InputProps={{
            startAdornment: startAdornment
          }}
          aria-invalid={!!error}
          aria-describedby={error ? `${name}-error` : undefined}
        />
      ) : (
        <TextField
          {...register(name, validation)}
          id={name}
          data-cy={name}
          placeholder={placeholder}
          error={!!error}
          aria-invalid={!!error}
          disabled={disabled}
          aria-describedby={error ? `${name}-error` : undefined}
          InputProps={{
            startAdornment: startAdornment
          }}
        />
      )}

      {error && (
        <ErrorText id={name} showIcon={showErrorIcon}>
          {error.message}
        </ErrorText>
      )}
    </FormControl>
  )
}

export default TextInput
