import React from 'react'
import Typography from '@mui/material/Typography'
import LimitsBox from '../../../../components/LimitsBox'
import { RiskDecision } from '../../../../types/RiskDecision'
import { Company } from '../../../../types/Company'
import Box from '@mui/material/Box'
import theme from '../../../../assets/theme'
import { CheckType } from '../../../../types/enums/CheckType'
import { Controller, UseFormReturn } from 'react-hook-form'
import FormControl from '@mui/material/FormControl'
import SignedTermsCheck from '../../checkWizard/components/SignedTermsCheck'
import { DirectorContactForm } from './DirectorSoleTraderStartPage'
import useTrackVirtualPageView from '../../../../custom-hooks/useVirtualPageView'
import { VirtualPage } from '../../../../types/enums/VirtualPage'

const DirectorSoleTraderStartPageContent = ({
  riskDecision,
  company,
  form,
  hasCheckOfType
}: {
  riskDecision: RiskDecision
  company: Company
  form: UseFormReturn<DirectorContactForm, any, undefined>
  hasCheckOfType: (checkType: CheckType) => boolean
}) => {
  useTrackVirtualPageView(VirtualPage.SOLE_TRADER_ELIGIBLE)
  const { control, setValue } = form

  return (
    <Box
      sx={{
        marginTop: { xs: theme.spacing(24), sm: theme.spacing(32) }
      }}
    >
      <LimitsBox
        companyName={company.name}
        totalSpendingLimit={riskDecision.totalSpendingLimit}
        availableSpendingLimit={riskDecision.availableSpendingLimit}
        currency={riskDecision.currency}
        eligibilitySubject={'for Kriya PayLater'}
      />
      <Box
        sx={{
          mt: theme.spacing(12),
          mb: theme.spacing(16)
        }}
      >
        <Typography variant="body2" data-cy="content">
          Complete a quick onboarding to activate your spending limit. You can begin the onboarding right now by
          clicking the button below.
        </Typography>
      </Box>

      {hasCheckOfType(CheckType.SignedTerms) && (
        <Controller
          control={control}
          name="termsAndConditions"
          rules={{
            required: true
          }}
          render={({ field }) => (
            <Box>
              <FormControl fullWidth>
                <SignedTermsCheck
                  field={field}
                  setValue={(value) => setValue('termsAndConditions', value, { shouldValidate: true })}
                />
              </FormControl>
            </Box>
          )}
        />
      )}
    </Box>
  )
}

export default DirectorSoleTraderStartPageContent
