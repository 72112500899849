import { useCallback, useEffect } from 'react'
import SegmentWrapper from '../utils/metrics/segmentWindow'
import { TrackEventProperties } from '../types/TrackEventProperties'

const useTrackEvent = (initialEventName?: string, props?: TrackEventProperties) => {
  const triggerEvent = useCallback((eventName: string, props?: TrackEventProperties) => {
    SegmentWrapper.track(eventName, props)
  }, [])

  useEffect(() => {
    if (initialEventName) {
      triggerEvent(initialEventName, props)
    }
  }, [initialEventName, props, triggerEvent])

  return triggerEvent
}

export default useTrackEvent
